/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ControlButton from "../Controls/ControlButton";
import Popover from "components/Popover";
import Info from "components/Info";
import LayersActions from "pages/platform/Sidebar/MapLayers/Layers/LayersActions";

/*
 * Função LayersSupport
 */
function LayersSupport() {
  const layers = useSelector((state) => state.layers);
  const { layers_groups } = layers.data || {};
  const [activeModal, setActiveModal] = useState(null);
  const [showPopOver, setShowPopOver] = useState(false);

  const openPopOver = () => {
    setShowPopOver((prev) => !prev);
  };
  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div className="tcf-map--control__btn--container--nofill">
      <ControlButton
        Action={openPopOver}
        type="layers"
        title={i18n.t("layers")}
        active={showPopOver}
        className="tcf-layers-support-controle"
      />
      <Popover
        showModal={showPopOver}
        setShowModal={setShowPopOver}
        className="tcf-layers-support-popover"
        size="lg"
        style={{
          display: "block",
          maxWidth: "initial",
          inset: "0px 0px auto auto",
          transform: "translate(-33px, -264px)",
        }}
        content={
          <div className="tcf-sidebar__territory--selector-layer-items">
            {layers_groups &&
              layers_groups.map(
                (group, idxg) =>
                  !group.main_menu &&
                  group.layers_names.map((item, idx) => (
                    <div
                      key={`key_${idx}`}
                      style={
                        !item.layer_link.link && item.layer_link.is_wms
                          ? { opacity: 0.5 }
                          : {}
                      }
                      className={`tcf-sidebar__territory--selector-layer-item ${
                        item.layer_link.activated
                          ? "tcf-sidebar__territory--selector-layer-item--active"
                          : ""
                      }`}
                    >
                      <div className="tcf-sidebar__territory--selector-layer-item-info">
                        <span className="tcf-sidebar__territory--selector-layer-item-info--title">
                          {item.name}
                        </span>
                        <button
                          type="button"
                          className="tcf-map-layer-show-popover"
                          onClick={() => setActiveModal(item.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M7.20001 13C10.5137 13 13.2 10.3137 13.2 7C13.2 3.68629 10.5137 1 7.20001 1C3.8863 1 1.20001 3.68629 1.20001 7C1.20001 10.3137 3.8863 13 7.20001 13Z"
                              stroke="#AFA7A1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.20001 9.4V7"
                              stroke="#AFA7A1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.20001 4.60004H7.20601"
                              stroke="#AFA7A1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <Info
                          item={item}
                          showModal={activeModal === item.id}
                          setShowModal={() => setActiveModal(null)}
                        />
                      </div>
                      <LayersActions
                        indexGroup={idxg}
                        item={item}
                        listAll={group.layers_names}
                      />
                    </div>
                  ))
              )}
          </div>
        }
      />
    </div>
  );
}
export default withTranslation()(LayersSupport);
