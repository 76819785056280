/* eslint-disable react/jsx-no-useless-fragment */
import i18n from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { carData } from "store/actions/carStatusConditionActions";
import { getLanguage } from "service/axios.service";
import AnimationCount from "./AnimationCount";
import { InfoBtn } from "./infoBtn";
import Info from "components/Info";

const CarCondition = () => {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { territorialCut, territory, type } = choice || {};
  const { value: territorialCutValue } = territorialCut || {};
  const { value: territoryValue } = territory || {};

  const { carInner } = useSelector((state) => state.car);
  const { condition: conditionArr } = carInner || {};

  /* Controle do modal de informações */
  const [carInfoModal, setCarInfoModal] = useState(false);
  const toogleCarInfoModal = () => setCarInfoModal(!carInfoModal);

  /* Só buscar dados em condições especificas: Brasil, estado, bioma ou município selecionado */
  const shouldGetCarData =
    type === "rural_properties" &&
    (territorialCutValue === "brazil" ||
      (["biome", "state", "rural_properties"].includes(territorialCutValue) &&
        territoryValue));

  useEffect(() => {
    if (shouldGetCarData) dispatch(carData(choice));
  }, [choice]);

  /* Informações só disponíveis em pt-br */
  const showInfoBtn = getLanguage() === "pt-BR";

  /* Se não buscar informações exiba o disclaimer */
  if (!shouldGetCarData) {
    return <div style={{ width: "100%" }}>{i18n.t("select_territory")}</div>;
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {showInfoBtn && <InfoBtn onClick={toogleCarInfoModal} />}

      {!carInfoModal && (
        <div className="tcf-map-report--body-territorialcut--ischildren">
          {conditionArr?.conditions.map(({ label, value }, idx) => (
            <div
              key={idx}
              className="tcf-map-report--body-territorialcut--ischildren-item"
            >
              <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
                {label}
              </div>
              <div className="tcf-map-report--body-territorialcut--ischildren-item--body box-counter">
                <AnimationCount value={Number(value)} color="#78C753" />
              </div>
            </div>
          ))}
        </div>
      )}

      {carInfoModal && (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span className="tcf-map-layer-popover-header-title">
            <h2>
              Categorização da Condição e Descrição (Portaria MAPA n. 121/2021):
            </h2>
          </span>
          <strong>1. Em análise:</strong>
          <p className="new-line">
            Os dados declarados no registro do imóvel rural no CAR estão em
            análise pelo órgão competente. O registro do imóvel rural no CAR foi
            analisado pelo órgão competente e o proprietário ou possuidor rural
            foi notificado (exclusão da Portaria MAPA n. 121/2021). Os dados
            declarados no registro do imóvel rural no CAR estão em análise pelo
            órgão competente (inclusão na Portaria MAPA n. 121/2021).
          </p>
          <strong>
            2. Analisado, aguardando regularização ambiental (Lei n°
            12.651/2012):
          </strong>
          <p className="new-line">
            O registro do imóvel rural no CAR foi analisado pelo órgão
            competente e foram identificadas Áreas de Preservação Permanente, de
            Reserva Legal e de uso restrito passíveis de regularização
            ambiental, com vistas a se adequar à Lei n° 12.651, de 2012; o
            proprietário ou possuidor rural foi notificado.
          </p>
          <strong>3. Aguardando análise:</strong>
          <p className="new-line">
            O registro do imóvel rural no Cadastro Ambiental Rural (CAR) foi
            realizado com sucesso e o processo aguarda a análise dos dados pelo
            órgão competente.
          </p>

          <strong>4. Cancelado:</strong>
          <p className="new-line">Cancelado.</p>

          <strong>
            5. Analisado, em conformidade com a Lei n° 12.651/2012:
          </strong>
          <p className="new-line">
            O registro do imóvel rural no CAR foi analisado pelo órgão
            competente e está em conformidade com a Lei n° 12.651, de 2012, no
            que se refere à regularidade ambiental das Áreas de Preservação
            Permanente, de Reserva Legal e de uso restrito (exclusão da Portaria
            MAPA n. 121/2021).
          </p>

          <strong>
            6. Analisado, em regularização ambiental (Lei n° 12.651/2012):
          </strong>
          <p className="new-line">
            O registro do imóvel rural no CAR foi analisado pelo órgão
            competente, foram identificadas Áreas de Preservação Permanente, de
            Reserva Legal e de uso restrito passíveis de regularização
            ambiental, com vistas a se adequar à Lei n° 12.651, de 2012, e o
            imóvel rural está em processo de regularização ambiental das áreas.
          </p>
        </div>
      )}
    </div>
  );
};

export default CarCondition;
