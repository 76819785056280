import { GeoJSON } from "ol/format";
import { Tile, Vector } from "ol/layer";
import { TileWMS, Vector as SourceVector } from "ol/source";
import { isArray, sortArrayOfObject } from "helpers/utils";
import { olMap } from "pages/platform/Map/controlMap";
import {
  actionsImplementationLawStyle,
  analyzeAndValidationStyle,
  praStyle,
  transparencyStyle,
  zeeStyle,
} from "pages/platform/Map/styleLayers";
import * as acoesActions from "./acoesActions";
import * as analiseActions from "./analiseActions";
import * as layerActions from "./layerActions";
import * as transparenciaActions from "./transparenciaActions";
import * as praActions from "./praActions";
import { map, layer } from "./types";
import * as zeeActions from "./zeeActions";

/*
 * estilo das camadas que não são wms
 */
const styles = {
  capacidade_institucional: actionsImplementationLawStyle,
  acoes_para_inscricao: actionsImplementationLawStyle,
  analise_do_car: actionsImplementationLawStyle,
  implementacao_do_pra: actionsImplementationLawStyle,
  numero_de_car_analisado: analyzeAndValidationStyle,
  numero_de_car_validado: analyzeAndValidationStyle,
  numero_de_temos_de_compromisso_firmado: analyzeAndValidationStyle,
  transparencia_ativa: transparencyStyle,
  transparencia_passiva: transparencyStyle,
  pra_adesao: praStyle,
  pra_compromisso: praStyle,
  zee: zeeStyle,
};

/*
 * pega um layer pelo nome
 */
export const getLayerByName = (name) =>
  olMap()
    .getLayers()
    .getArray()
    .find((layer) => layer.get("name") === name);

/*
 * Progress load layers
 * -----------------------------------------------------
 */
export const loadStart = () => (dispatch) => {
  dispatch({ type: map.LOAD_LAYER_START });
};

export const loadEnd = () => (dispatch) => {
  dispatch({ type: map.LOAD_LAYER_END });
};

export const loadError = (layerName) => (dispatch) => {
  if (layerName) {
    const layerError = getLayerByName(layerName);
    layerError.setVisible(false);
  }

  dispatch({ type: map.LOAD_LAYER_ERROR });
};

export const setProgress = (source, layerName) => (dispatch) => {
  let loading = 0;
  let loaded = 0;

  // marks the start of an HTTP request for a tile
  source.on("tileloadstart", () => {
    loading += 1;
    if (loading === 1) dispatch(loadStart());
  });

  // marks the end of an HTTP request for a tile
  source.on("tileloadend", () => {
    loaded += 1;
    if (loading === loaded) dispatch(loadEnd());
  });

  // marks the start of an HTTP request for an image
  source.on("imageloadstart", () => {
    loading += 1;
    if (loading === 1) dispatch(loadStart());
  });

  // marks the end of an HTTP request for an image
  source.on("imageloadend", () => {
    loaded += 1;
    if (loading === loaded) dispatch(loadEnd());
  });

  // error handling
  source.on("tileloaderror", () => {
    loaded += 1;
    if (loading === loaded) dispatch(loadError(layerName));
  });

  source.on("imageloaderror", () => {
    loaded += 1;
    if (loading === loaded) dispatch(loadError(layerName));
  });
};
// -----------------------------------------------------

/*
 * adiciona os layers WMS no mapa
 */
export const addLayerWms = (item) => (dispatch) => {
  const { layer_link, name, zIndex } = item || {};
  const { link, name_mapfile } = layer_link || {};
  const [base, query] = link.split("?");
  const params = query.split("&");
  let LAYERS = "";
  let url = base;

  params.forEach((p) => {
    if (p.indexOf("LAYERS") !== -1 || p.indexOf("layers") !== -1) {
      [, LAYERS] = p.split("=");
    }

    // para verificar se é do mapserver
    if (p.indexOf("MAP") !== -1) {
      url += `?${p}`;
    }
  });

  const sourceWms = new TileWMS({
    url,
    params: { LAYERS, name_mapfile },
  });

  const tile = new Tile({
    source: sourceWms,
    name: name_mapfile,
    label: name,
    zIndex: zIndex,
  });

  olMap().addLayer(tile);

  dispatch(setProgress(sourceWms, tile.get("name")));
};

/*
 * adiciona os layers GeoJson no mapa
 */
export const addLayerGeoJson = (item) => async (dispatch, getState) => {
  const { layer_link, name, zIndex } = item || {};
  const { name_mapfile } = layer_link || {};
  const { statesGeojson } = getState().layers;

  //console.log(item);
  //console.log(getState())

  dispatch(loadStart());

  try {
    // geometria dos estados
    // ------------------------------------------------------
    const statesGeo =
      !isArray(statesGeojson) || statesGeojson.length === 0
        ? await dispatch(layerActions.readStatesGeojson())
        : statesGeojson;
    // ------------------------------------------------------

    // VECTOR LAYER
    // -----------------------------------------------------
    // source do layer de estados
    const sourceVector = new SourceVector({
      features: new GeoJSON().readFeatures(statesGeo, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
    });

    // vector do layer de estados
    const layerVector = new Vector({
      source: sourceVector,
      name: name_mapfile,
      label: name,
      zIndex: zIndex,
      style: styles[name_mapfile],
    });

    const features = sourceVector.getFeatures();
    // -----------------------------------------------------

    // Ações para implementação da lei
    // - Capacidade Institucional
    // - Ações para Inscrição
    // - Análise do CAR
    // - Implementação do PRA
    // ---------------------------------------------------------------------
    const apidl = [
      "capacidade_institucional",
      "acoes_para_inscricao",
      "analise_do_car",
      "implementacao_do_pra",
    ];

    if (apidl.includes(name_mapfile)) {
      const { acoesInner } = getState().acoes;

      const actionsLaw =
        !acoesInner || !isArray(acoesInner) || acoesInner.length === 0
          ? await dispatch(acoesActions.acoesData())
          : acoesInner;

      actionsLaw.forEach((r) => {
        const { law_implementation } = r || {};
        const law =
          law_implementation &&
          law_implementation.find((l) => l.key === name_mapfile);

        const { criteria, totalPontuation } = law || {};
        const totalCriteria = criteria ? criteria.length : 0;

        let criteriasSituation = "attend-partial";
        if (totalPontuation === 0) {
          criteriasSituation = "does-not-attend";
        }
        if (totalPontuation === totalCriteria) {
          criteriasSituation = "attend";
        }

        const feat = features.find((f) => f.get("geocode") === r.geocode);

        feat.set("key", "criterias");
        feat.set("criterias", criteriasSituation);
      });
    }
    // ---------------------------------------------------------------------

    // Análise e validação
    // - Número de CAR analisado
    // - Número de CAR validado
    // - Número de Termos de Compromisso firmado
    // ----------------------------------------------------------------------
    const av = [
      "numero_de_car_analisado",
      "numero_de_car_validado",
      "numero_de_temos_de_compromisso_firmado",
    ];

    if (av.includes(name_mapfile)) {
      const { analiseInner } = getState().analise;

      const analyze =
        !analiseInner || !isArray(analiseInner) || analiseInner.length === 0
          ? await dispatch(analiseActions.analiseData())
          : analiseInner;

      analyze.forEach((r) => {
        const feat = features.find((f) => f.get("geocode") === r.geocode_uf);
        const value = {
          numero_de_car_analisado: r.number_car_analysis,
          numero_de_car_validado: r.number_car_validation,
          numero_de_temos_de_compromisso_firmado: r.number_tcs_signed,
        };
        feat.set("key", "analyze");
        feat.set("analyze", value[name_mapfile]);
      });
    }
    // ----------------------------------------------------------------------

    // transparência ativa ou passiva
    // -----------------------------------------------------------------------
    if (name_mapfile.includes("transparencia")) {
      const { transparenciaInner: transpInner } = getState().transparencia;

      const transparency =
        !transpInner || !isArray(transpInner) || transpInner.length === 0
          ? await dispatch(transparenciaActions.transparenciaData())
          : transpInner;

      transparency.forEach((r) => {
        const feat = features.find((f) => f.get("geocode") === r.geocode_uf);
        const value = {
          transparencia_ativa: r.active,
          transparencia_passiva: r.passive,
        };
        feat.set("unity", "%");
        feat.set("key", "transparency");
        feat.set("transparency", parseInt(value[name_mapfile], 10));
      });
    }
    // -----------------------------------------------------------------------

    // Programa de Regularização Ambiental
    // -----------------------------------------------------------------------

    const pra_options = ["pra_adesao", "pra_compromisso"];

    if (pra_options.includes(name_mapfile)) {
      const { praInner } = getState().pra;

      const praData =
        !praInner || !isArray(praInner) || praInner.length === 0
          ? await dispatch(praActions.praData())
          : praInner;

      praData.forEach((r) => {
        const feat = features.find((f) => f.get("geocode") === r.geocode);
        const featInfo = {
          pra_adesao: r.adesao,
          pra_compromisso: r.termo_comp,
        };

        //const underChecking = [16, 23, 52, 33, 14, 17]; //Array de estados cujos dados estão sendo reavaliados
        //feat.set("unity", underChecking.includes(r.geocode) ? " (Em conferência)" : "");

        feat.set("key", "pra");
        feat.set("pra", featInfo[name_mapfile]);
      });
    }
    // -----------------------------------------------------------------------

    // ZEE
    // -----------------------------------------------------------------------
    if (name_mapfile.includes("zee")) {
      const { zeeInner } = getState().zee;

      const zee =
        !zeeInner || !isArray(zeeInner) || zeeInner.length === 0
          ? await dispatch(zeeActions.zeeData())
          : zeeInner;

      zee.forEach((r) => {
        const feat = features.find((f) => f.get("geocode") === r.geocode);
        feat.set("key", "zeeName");
        feat.set("zeeName", r.zee_situation.name);
        feat.set("zee", parseInt(r.situation_id, 10)); // para controle do estilo
      });
    }
    // -----------------------------------------------------------------------

    olMap().addLayer(layerVector);

    dispatch(loadEnd());
  } catch (e) {
    dispatch(loadError());
  }
};

/*
 * seta no reducer os layers ativados
 */
export const setActivatedLayers = () => (dispatch) => {
  const ls = olMap().getLayers().getArray();
  const activatedLayers = ls.filter((l) => {
    const props = l.getProperties();
    if (l.getVisible() && !props.base) {
      return true;
    }
  });

  dispatch({ type: map.SET_ACTIVATED_LAYERS, payload: activatedLayers });
};

export const getZIndexAndNameLayers = () => {
  const arr = [];
  olMap()
    .getLayers()
    .getArray()
    .forEach((l) => {
      const zIndex = l.getZIndex();
      const name = l.get("name");
      if (zIndex && name) arr.push({ zIndex, name });
    });

  arr.sort(sortArrayOfObject("zIndex"));

  return arr;
};

const renderMinMaxZIndexs = () => {
  const zIndexs = [];
  olMap()
    .getLayers()
    .getArray()
    .forEach((l) => {
      const zIndex = l.getZIndex();
      if (zIndex) zIndexs.push(zIndex);
    });

  return zIndexs;
};

export const getMinZIndex = () => {
  const zIndexs = renderMinMaxZIndexs();
  return zIndexs.length > 0 ? Math.min.apply(null, zIndexs) : 0;
};

export const getMaxZIndex = () => {
  const zIndexs = renderMinMaxZIndexs();
  return zIndexs.length > 0 ? Math.max.apply(null, zIndexs) : 0;
};

/*
 * seta a camada como visível ou não
 */
export const setLayerVisible =
  (item, listAll, visibility) => async (dispatch) => {
    const { layer_link } = item || {};
    const { name_mapfile, is_wms, land_cutout } = layer_link || {};
    const layer = getLayerByName(name_mapfile);

    // zIndex
    if (visibility) {
      const zIndexs = renderMinMaxZIndexs();

      // ### zIndexs para as camadas do menu principal (zIndexs menores que 10000) ###
      // ------------------------------------------------------------------------------
      const zIndexL10000 = zIndexs.filter((value) => value < 10000);
      // Pegar o máximo valor menor que 10000
      const zIndexL =
        zIndexL10000.length > 0 ? Math.max.apply(null, zIndexL10000) : 0;
      // ------------------------------------------------------------------------------

      // ### zIndexs exclusivos para camadas de apoio (zIndexs maiores que 10000) ###
      // ------------------------------------------------------------------------------
      const zIndexG10000 = zIndexs.filter((value) => value > 10000);
      // Pegar o máximo valor maior que 10000
      const zIndexG =
        zIndexG10000.length > 0 ? Math.max.apply(null, zIndexG10000) : 10000;
      // ------------------------------------------------------------------------------

      item.zIndex =
        land_cutout === "supporting_layers" ? zIndexG + 1 : zIndexL + 1;
    }

    // se não for wms desativa todas as camadas do tipo radio do grupo
    if (!is_wms) {
      if (isArray(listAll)) {
        listAll.map((l) => {
          if (l.layer_link.is_type_radio) {
            const layer = getLayerByName(l.layer_link.name_mapfile);
            if (layer) {
              layer.setVisible(false);
            }
          }
        });
      }
    }

    if (layer) {
      layer.setVisible(visibility);
      layer.setZIndex(item.zIndex);
    } else {
      if (is_wms) {
        await dispatch(addLayerWms(item));
      } else {
        item.zIndex = 100000;
        await dispatch(addLayerGeoJson(item));
      }
    }

    dispatch(setActivatedLayers());
  };

/*
 * para ativar e desativar os layers
 */
export const toggleLayer = (params) => (dispatch) => {
  const { checked, item, listAll } = params || {};
  const { layer_link } = item || {};
  const { link, is_wms } = layer_link || {};

  const newLink = link
    ? link.replace("HOST_MAPSERVER", process.env.REACT_APP_HOST_MAPSERVER)
    : link;

  layer_link.link = newLink;
  item.visible = checked;

  if (link || !is_wms) dispatch(setLayerVisible(item, listAll, checked));
};

export const renderLayersActivaded = () => (dispatch, getState) => {
  const { data } = getState().layers;
  const { layers_groups } = data || {};

  if (layers_groups && layers_groups.length > 0) {
    layers_groups.forEach((group) => {
      group.layers_names.forEach((item) => {
        const { checked, layer_link } = item || {};
        const { activated } = layer_link || {};
        if (checked || activated) {
          item.checked = undefined;
          dispatch(
            toggleLayer({
              checked: activated,
              item,
              listAll: group?.layers_names,
            })
          );
        }
      });
    });
  }

  dispatch({
    type: layer.LAYERS_READ_SUCCESS,
    payload: data,
  });
};

/*
 * altera a opacidade das camadas
 */
export const handleOpacityLayer = (params) => () => {
  const { value, item } = params || {};
  const { layer_link } = item || {};
  const { name_mapfile } = layer_link || {};

  const layer = getLayerByName(name_mapfile);

  const opacity = (100 - value) / 100;

  if (layer) layer.setOpacity(opacity);
};

/*
 * altera a ordem das camadas
 */
export const handleOrderLayer = (params) => () => {
  const { layerItems } = params || {};

  const layerItemsChecked = layerItems.filter((item) => item.checked);

  layerItemsChecked.forEach((item, index) => {
    const { layer_link } = item || {};
    const { name_mapfile } = layer_link || {};

    const layer = getLayerByName(name_mapfile);

    const zIndex = layerItemsChecked.length - index;

    if (layer) layer.setZIndex(zIndex);
  });
};
