import api, { getLanguage } from "service/axios.service";
import {
  CARSTATUSCONDITION_REQUEST,
  CARSTATUSCONDITION_SUCCESS,
  CARSTATUSCONDITION_FAIL,
} from "../constants/carStatusConditionConstants";

export const carData = (choice) => async (dispatch) => {
  const { territorialCut, territory } = choice || {};
  const { value: valueCut } = territorialCut || {};
  const { label, value } = territory || {};

  const langId = getLanguage() === "en-US" ? 2 : 1;

  const newValueCut =
    valueCut === "rural_properties" || valueCut === "traditional_territories"
      ? "municipality"
      : valueCut;

  dispatch({ type: CARSTATUSCONDITION_REQUEST });
  try {
    const res_condition = await api.post("/v1/car-condition-data", {
      territorialCut: newValueCut,
      nameTerritory: [label],
      geocodes: [value],
      langId,
    });
    const data_condition = res_condition.data;

    const payload = {
      condition: data_condition,
    };

    dispatch({
      type: CARSTATUSCONDITION_SUCCESS,
      payload,
    });

    return payload;
  } catch (error) {
    dispatch({
      type: CARSTATUSCONDITION_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
