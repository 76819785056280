/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
export function InfoBtn({ onClick }) {
  return (
    <button
      type="button"
      className="tcf-map-layer-show-popover"
      onClick={onClick}
      style={{ position: "absolute", top: "-43px", left: "130px" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M7.20001 13C10.5137 13 13.2 10.3137 13.2 7C13.2 3.68629 10.5137 1 7.20001 1C3.8863 1 1.20001 3.68629 1.20001 7C1.20001 10.3137 3.8863 13 7.20001 13Z"
          stroke="#AFA7A1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.20001 9.4V7"
          stroke="#AFA7A1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.20001 4.60004H7.20601"
          stroke="#AFA7A1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
